'use client'

import { useState } from 'react'
import { Button, Card, Cell, Icon, Spacer, Text } from '@vinted/web-ui'
import { ExclamationCircleFilled32 } from '@vinted/monochrome-icons'

import { ItemAlertStatus } from 'constants/item'
import { EDIT_ITEM_URL_WITH_ANCHOR } from 'constants/routes'
import useTranslate from 'hooks/useTranslate'
import useFetch from 'hooks/useFetch'
import { getPhotoTip } from 'data/api'
import { transformPhotoTipResponse } from 'data/api/transformers/response'
import PhotoTips from 'components/PhotoTips/PhotoTips'
import useSession from 'hooks/useSession'

import { ItemPageAlertsPluginModel } from './types'

type CtaButtonProps = Partial<ComponentProps<typeof Button>> | null

type Props = {
  data: ItemPageAlertsPluginModel
  isUserAdmin: boolean
}

const ItemPageAlertsPlugin = ({ data, isUserAdmin }: Props) => {
  const { itemId, itemUserId, itemAlert } = data
  const [isPhotoTipsOpen, setIsPhotoTipsOpen] = useState(false)
  const userId = useSession().user?.id
  const translate = useTranslate('item.alerts')
  const { fetch: fetchPhotoTip, transformedData: photoTip } = useFetch(
    getPhotoTip,
    transformPhotoTipResponse,
  )
  const isOwner = userId === itemUserId

  if ((!isUserAdmin && !isOwner) || !itemAlert?.detailed_description) return null

  const closePhotoTips = () => setIsPhotoTipsOpen(false)

  const openPhotoTips = () => {
    if (!itemAlert.photo_tip_id) return

    setIsPhotoTipsOpen(true)

    if (photoTip) return

    fetchPhotoTip(itemAlert.photo_tip_id)
  }

  const getCtaButtonProps = (): CtaButtonProps => {
    if (itemAlert.photo_tip_id)
      return {
        text: translate('actions.learn_more'),
        onClick: openPhotoTips,
      }

    switch (itemAlert.item_alert_type) {
      case ItemAlertStatus.PackageSize: {
        return {
          text: translate('actions.package_size'),
          url: EDIT_ITEM_URL_WITH_ANCHOR(itemId, 'packages-selector'),
        }
      }
      case ItemAlertStatus.MissingSubcategory: {
        return {
          text: translate('actions.missing_subcategory'),
          url: EDIT_ITEM_URL_WITH_ANCHOR(itemId, 'categories'),
        }
      }
      default:
        return null
    }
  }

  const renderCtaButton = () => {
    const ctaButtonProps = getCtaButtonProps()

    if (!ctaButtonProps) return null

    return (
      <div className="u-flexbox u-align-items-center">
        <Spacer orientation={Spacer.Orientation.Vertical} />
        <Button
          {...ctaButtonProps}
          styling={Button.Styling.Filled}
          size={Button.Size.Medium}
          testId="item-alert-cta-button"
        />
      </div>
    )
  }

  return (
    <>
      <Card>
        <Cell suffix={renderCtaButton()} testId="item-alert-box">
          <div className="u-flexbox u-align-items-center">
            <Icon name={ExclamationCircleFilled32} color={Icon.Color.Warning} />
            <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Large} />
            <Text
              text={itemAlert.detailed_description}
              type={Text.Type.Subtitle}
              theme="amplified"
            />
          </div>
        </Cell>
      </Card>
      <Spacer />
      {itemAlert.photo_tip_id && photoTip && (
        <PhotoTips isOpen={isPhotoTipsOpen} photoTips={[photoTip]} handleClose={closePhotoTips} />
      )}
    </>
  )
}

export default ItemPageAlertsPlugin
