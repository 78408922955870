'use client'

import { useEffect } from 'react'
import { Spacer } from '@vinted/web-ui'
import classNames from 'classnames'

import ItemPageBuyerProtectionInfoPlugin from 'pages/Item/plugins/BuyerProtectionInfo'
import ItemPageShippingPricePlugin from 'pages/Item/plugins/ShippingPrice'
import ItemPageDescriptionPlugin from 'pages/Item/plugins/Description'
import { PluginName } from 'pages/Item/types'

import { ItemDto, ItemPagePluginEntityDto } from 'types/dtos'
import { Plugins } from 'pages/Item/hooks'
import useAbTest from 'hooks/useAbTest'
import useAbTestExposeTracking from 'hooks/useAbTestExposeTracking'
import useFeatureSwitch from 'hooks/useFeatureSwitch'
import { AbTestVariant } from 'constants/abtest'

import ItemPagePricingPlugin from '../../plugins/Pricing/ItemPagePricingPlugin'
import ItemActions from '../../ItemActions'
import ItemPageOfflineVerificationBlockPlugin from '../../plugins/OfflineVerificationBlock'
import ItemElectronicsVerificationBlockPlugin from '../../plugins/ElectronicsVerificationBlock'
import Plugin from '../../plugins/Plugin'

type Props = {
  item: ItemDto
  data: Plugins | null
  serverSidePlugins?: Array<ItemPagePluginEntityDto>
}

const DetailsSection = ({ item, data, serverSidePlugins }: Props) => {
  const buyerHoldoutAbTestEnabled =
    useAbTest({ abTestName: 'buyer_domain_holdout_2024q4' })?.variant === AbTestVariant.On
  const summaryPluginAbTestEnabled =
    useAbTest({ abTestName: 'item_page_summary_plugin_web' })?.variant === AbTestVariant.On &&
    buyerHoldoutAbTestEnabled
  const { trackExpose: trackSummaryPluginExposure } = useAbTestExposeTracking({
    abTestName: 'item_page_summary_plugin_web',
    shouldTrackOnce: true,
  })
  const isDescriptionFromBeEnabled = useFeatureSwitch('description_plugin_web')

  useEffect(() => {
    if (buyerHoldoutAbTestEnabled) trackSummaryPluginExposure()
  }, [buyerHoldoutAbTestEnabled, trackSummaryPluginExposure])

  return (
    <>
      <div className="details-list details-list--main-info">
        <Plugin data={serverSidePlugins?.find(plugin => plugin.name === PluginName.Summary)} />
        {data?.pricing && <ItemPagePricingPlugin data={data.pricing} />}
        <Plugin data={serverSidePlugins?.find(plugin => plugin.name === PluginName.Attributes)} />
      </div>
      <div
        className={classNames('details-list__info', {
          'details-list__info--without-title': summaryPluginAbTestEnabled,
        })}
      >
        {isDescriptionFromBeEnabled ? (
          <Plugin
            data={serverSidePlugins?.find(plugin => plugin.name === PluginName.Description)}
          />
        ) : (
          data?.description && (
            <ItemPageDescriptionPlugin
              itemId={data.description.itemId}
              title={data.description.title}
              localization={data.description.localization}
              description={data.description.description}
            />
          )
        )}
        {data?.shippingPrice && <ItemPageShippingPricePlugin data={data.shippingPrice} />}
        <Spacer size={Spacer.Size.Large} />
        <div className="details-list__item details-list--actions">
          <ItemActions item={item} />
          <Spacer />
        </div>
        <div className="details-list--shipping-info">
          {data?.offlineVerificationBlock && (
            <ItemPageOfflineVerificationBlockPlugin data={data.offlineVerificationBlock} />
          )}
          {data?.electronicsVerificationBlock && (
            <ItemElectronicsVerificationBlockPlugin data={data.electronicsVerificationBlock} />
          )}
          {data?.buyerProtectionInfo && (
            <ItemPageBuyerProtectionInfoPlugin data={data.buyerProtectionInfo} />
          )}
        </div>
      </div>
    </>
  )
}

export default DetailsSection
