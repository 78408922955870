'use client'

import { useMemo, useRef } from 'react'
import { InView } from 'react-intersection-observer'
import { Label, Text } from '@vinted/web-ui'

import { ContentSource } from 'constants/tracking/content-sources'
import { Screen } from 'constants/tracking/screens'
import useFetch from 'hooks/useFetch'
import useLocation from 'hooks/useLocation'
import useTranslate from 'hooks/useTranslate'
import { getSimilarItemsById } from 'data/api'
import ContentLoader from 'components/ContentLoader'
import ItemViewItems from 'components/ItemViewItems'
import { MORE_SIMILAR_ITEMS_URL } from 'constants/routes'
import useTracking from 'hooks/useTracking'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent, viewEvent } from 'libs/common/event-tracker/events'
import { ViewableElement } from 'constants/tracking/viewable-elements'
import { transformSimilarItemDtoToProductItem } from 'data/transformers'

import { ItemPageSimilarItemsPluginModel, SimilarItemModel } from './types'

const MAX_ITEM_COUNT = 6

type Props = {
  data?: ItemPageSimilarItemsPluginModel
  horizontalScrollEnabled: boolean
  trackExposure(inView: boolean): void
}

const ItemPageSimilarItemsPlugin = ({ data, horizontalScrollEnabled, trackExposure }: Props) => {
  const { fetch: fetchSimilarItems, data: similarItemsData } = useFetch(getSimilarItemsById)
  const hasFetched = useRef(false)
  const seenCta = useRef(false)

  const { searchParams } = useLocation()
  const translate = useTranslate('item')
  const { track } = useTracking()

  const items: Array<SimilarItemModel> = useMemo(() => {
    return similarItemsData?.items.map(transformSimilarItemDtoToProductItem) || []
  }, [similarItemsData])

  const referrerScreen = searchParams.referrer?.toString() || Screen.Unknown

  if (!data) return null

  const handleInViewChange = (inView: boolean) => {
    if (inView && !hasFetched.current) {
      fetchSimilarItems({ itemId: data.itemId })
      hasFetched.current = true
    }

    trackExposure(inView)
  }

  const handleCtaClick = (target: ClickableElement) => () => {
    track(clickEvent({ target, screen: Screen.Item }))
  }

  const handleCtaView = () => {
    if (seenCta.current) return
    seenCta.current = true
    track(viewEvent({ target: ViewableElement.MoreSimilarItemsCardCta, screen: Screen.Item }))
  }

  const renderLoader = () =>
    !similarItemsData && (
      <div className="u-ui-margin-top-medium">
        <ContentLoader testId="item-feed-loader" />
      </div>
    )

  const renderLabelSuffix = () =>
    horizontalScrollEnabled &&
    items.length > MAX_ITEM_COUNT && (
      <a
        href={MORE_SIMILAR_ITEMS_URL(data.itemId)}
        onClick={handleCtaClick(ClickableElement.MoreSimilarItemsTitleCta)}
        data-testid="similar-items-title-suffix"
      >
        <Text
          type={Text.Type.Subtitle}
          theme="primary"
          text={translate('view_all_items')}
          as="span"
        />
      </a>
    )

  return (
    <InView as="section" data-testid="item-page-similar-items-plugin" onChange={handleInViewChange}>
      {renderLoader()}
      {items.length > 0 && (
        <>
          <Label
            text={<Text type={Text.Type.Subtitle}>{translate('similar_items_title')}</Text>}
            suffix={renderLabelSuffix()}
          />
          <ItemViewItems
            items={items}
            contentSource={ContentSource.SimilarItems}
            itemTestId="similar-item"
            refererScreen={referrerScreen}
            ctaUrl={MORE_SIMILAR_ITEMS_URL(data.itemId)}
            horizontalScrollEnabled={horizontalScrollEnabled}
            onCtaClick={handleCtaClick(ClickableElement.MoreSimilarItemsCardCta)}
            onCtaView={handleCtaView}
          />
        </>
      )}
    </InView>
  )
}

export default ItemPageSimilarItemsPlugin
