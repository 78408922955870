'use client'

import { isAxiosError } from 'axios'
import { useSelector } from 'react-redux'

import { CATALOG_ID_URL, MEMBER_PROFILE_URL } from 'constants/routes'
import { UserAccountStatus } from 'constants/user'
import { Screen } from 'constants/tracking/screens'
import { ItemPageRedirectReason } from 'constants/item'
import {
  getItemDetailsRequest,
  getProfileUserInfoRequest,
  getShippingDetailsRequest,
  getEscrowFeesRequest,
} from 'data/api/requests'
import { HttpStatus } from 'data/api/response-codes'
import { ItemDto, ItemPagePluginEntityDto } from 'types/dtos'
import Item from 'pages/Item'
import { urlWithParams } from 'libs/utils/url'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { withMemoizedPromise } from 'libs/utils/promise'
import { ItemShippingDetailsModel } from 'types/models/shipping-option'
import { EscrowFeesModel } from 'types/models/escrow-fees'
import {
  transformGetShippingDetails,
  transformItemEscrowFeesResponse,
} from 'data/api/transformers/response'

import PageHead from '../../../components/Head/PageHead'
import fullPageLayout from '../../../layouts/FullPage'
import { moneyObjectHeader } from '../../../constants/api'
import { extractItemIdFromSlug } from '../../../libs/server-utils/pages/item'
import { getLayoutServerSideProps } from '../../../layouts/FullPage/server-props'
import { transformProductPageSchemaMarkup } from '../../../libs/seo/schema-markup/transformers'
import { metrics } from '../../../libs/server-utils/metrics'

const ITEM_ID_REGEX = /^(\d+)(-[a-zA-Z0-9-]+)?$/

export type Props = {
  itemDto: ItemDto
  shippingDetails: ItemShippingDetailsModel | null
  plugins?: Array<ItemPagePluginEntityDto>
  escrowFees?: EscrowFeesModel | null
}

const ItemPage = ({ itemDto, shippingDetails, plugins, escrowFees }: Props) => {
  const description = `${itemDto.title} - ${itemDto.description}`
  const isSchemaMarkupEnabled = useSelector(getIsFeatureSwitchEnabled('schema_markup_item_page'))

  const og = {
    url: itemDto.url,
    type: 'product',
    title: itemDto.title,
    brand: itemDto.brand_dto?.title,
    image: itemDto.photos.length ? itemDto.photos[0]!.url : undefined,
    productId: itemDto.id.toString(),
    description: itemDto.description,
    priceAmount: itemDto.price.amount,
    priceCurrency: itemDto.price.currency_code,
  }

  const itemPageSchemaMarkup = isSchemaMarkupEnabled
    ? transformProductPageSchemaMarkup(itemDto)
    : undefined

  return (
    <>
      <PageHead
        title={itemDto.title}
        meta={{ description, og }}
        jsonLd={itemPageSchemaMarkup}
        isIndexed
      />
      <Item
        itemDto={itemDto}
        shippingDetails={shippingDetails}
        plugins={plugins}
        escrowFees={escrowFees}
      />
    </>
  )
}

export default fullPageLayout<Props>(ItemPage, { isWhiteBackground: false })

export const getServerSideProps = getLayoutServerSideProps<Props>(
  async (context, serverProps) => {
    const stopItemPageLoadDurationTimer = metrics.itemPageLoadDuration.startTimer()
    metrics.itemPageLoadCount.inc({ state: 'initiated' })

    const { itemId: itemIdSlug } = context.query

    const { abTests } = serverProps
    const holidayModeRedirectAbTest = (await abTests)?.find(
      test => test.name === 'holiday_mode_redirect',
    )
    const isHolidayModeRedirectEnabled = holidayModeRedirectAbTest?.variant === 'on'

    const observeFailure = (reason: ItemPageRedirectReason) => {
      stopItemPageLoadDurationTimer({ state: 'failed' })
      metrics.itemPageLoadCount.inc({ state: 'failed', reason })
    }

    if (typeof itemIdSlug !== 'string') {
      observeFailure(ItemPageRedirectReason.IdSlugNotString)

      return { notFound: true }
    }

    if (!ITEM_ID_REGEX.test(itemIdSlug)) {
      observeFailure(ItemPageRedirectReason.IdDoesNotMeetRegex)

      return { notFound: true }
    }

    const itemId = extractItemIdFromSlug(itemIdSlug)

    if (!itemId) {
      observeFailure(ItemPageRedirectReason.IdDoesNotExist)

      return { notFound: true }
    }

    const fetchItemDetails = async () => {
      try {
        const { item, plugins } = await getItemDetailsRequest(serverProps.api)(itemId, {
          headers: moneyObjectHeader,
          throwError: true,
        })

        return { item, plugins }
      } catch (error) {
        const httpStatus = isAxiosError(error) && error.response?.status

        if (httpStatus === HttpStatus.NotFound) return null

        throw error
      }
    }

    const fetchShippingDetails = async () => {
      try {
        const response = await getShippingDetailsRequest(serverProps.api)(itemId, {
          headers: moneyObjectHeader,
          throwError: true,
        })

        return transformGetShippingDetails(response)
      } catch (error) {
        return null
      }
    }

    const fetchEscrowFees = async () => {
      try {
        const response = await getEscrowFeesRequest(serverProps.api)(itemId, {
          headers: moneyObjectHeader,
          throwError: true,
        })

        return transformItemEscrowFeesResponse(response)
      } catch (error) {
        return null
      }
    }

    const { item, plugins } = (await fetchItemDetails()) ?? {}

    if (!item) {
      observeFailure(ItemPageRedirectReason.ItemDoesNotExist)

      return { notFound: true }
    }

    const itemDtoIdSlug = item.path.split('/').pop()
    const isIdSlugMatching = itemIdSlug === itemDtoIdSlug

    if (!isIdSlugMatching) {
      observeFailure(ItemPageRedirectReason.IdSlugNotMatching)

      return {
        redirect: {
          destination: item.path,
          permanent: true,
        },
      }
    }

    const isCurrentUserSupport = withMemoizedPromise(async () => {
      const currentUser = await serverProps.user
      if (!currentUser) return false

      return getProfileUserInfoRequest(currentUser.id)(serverProps.api)({
        throwError: true,
      })
        .then(userInfoResponse => userInfoResponse.show_support_functionality)
        .catch(() => false)
    })

    const isOwnerBanned = item.user.account_status === UserAccountStatus.Banned

    if (isOwnerBanned && !(await isCurrentUserSupport())) {
      observeFailure(ItemPageRedirectReason.BannerOwner)

      return { notFound: true }
    }

    if (item.is_draft && !(await isCurrentUserSupport())) {
      observeFailure(ItemPageRedirectReason.DraftItem)

      return { notFound: true }
    }

    const isCurrentUserOwner = item.user.id === serverProps.user?.id
    const shouldRedirectToProfile = isHolidayModeRedirectEnabled ? item.user.is_on_holiday : false

    const canShowItem =
      (item.transaction_permitted && !shouldRedirectToProfile) ||
      (await isCurrentUserOwner) ||
      (await isCurrentUserSupport())

    if (!canShowItem) {
      observeFailure(ItemPageRedirectReason.PermittedTransaction)

      return {
        redirect: {
          destination: urlWithParams(MEMBER_PROFILE_URL(item.user.id), {
            on_holiday: shouldRedirectToProfile ? true : null,
          }),
          permanent: false,
        },
      }
    }

    if (!item.user.can_view_profile) {
      const url = urlWithParams(CATALOG_ID_URL(item.catalog_id), {
        flash_message_code: 'no_longer_available',
      })
      observeFailure(ItemPageRedirectReason.CannotViewProfile)

      return {
        redirect: {
          destination: url,
          permanent: true,
        },
      }
    }

    const [shippingDetails, escrowFees] = await Promise.all([
      fetchShippingDetails(),
      fetchEscrowFees(),
    ])

    stopItemPageLoadDurationTimer({ state: 'succeeded' })
    metrics.itemPageLoadCount.inc({ state: 'succeeded' })

    return {
      props: {
        itemDto: item,
        shippingDetails,
        escrowFees,
        plugins,
      },
    }
  },
  {
    id: 'item',
    screen: Screen.Item,
  },
)
