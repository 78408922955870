'use client'

import { ReactNode } from 'react'

import { ItemPagePluginEntityDto } from 'types/dtos/item-page-plugins'
import {
  ItemPageAttributesDto,
  ItemPageDescriptionDto,
  ItemPageSellerFeedbacksDto,
  ItemPageSummaryDto,
} from 'types/dtos'
import ExposureBlock from 'components/ExposureBlock'
import clientSideMetrics from 'libs/common/client-side-metrics'
import useAbTest from 'hooks/useAbTest'
import { AbTestVariant } from 'constants/abtest'

import ItemPageSellerReviewsPlugin, {
  ItemPageSellerFeedbacksPluginModel,
  getItemPageSellerFeedbacksPluginModel,
} from './SellerFeedbacks'
import {
  ItemPageAttributesBlockPlugin,
  ItemPageAttributesBlockPluginModel,
  getItemPageAttributesBlockPluginModel,
} from './AttributesBlock'
import { getItemPageSummaryPluginModel, ItemPageSummaryPluginModel } from './Summary'
import { PluginName } from '../types'
import { logItemPageError } from '../utils/observability'
import ItemPageSummaryPlugin from './Summary/ItemPageSummaryPlugin'
import ItemPageDescriptionPlugin from './Description/ItemPageDescriptionPlugin'
import { getItemPageDescriptionPluginModel } from './Description/transformers'
import { ItemPageDescriptionPluginModel } from './Description/types'

const ITEM_PAGE_PLUGIN_ERROR_COUNT_METRIC_NAME = 'item_page_plugin_error_count'

type PluginConfig<TBackendData, TProps> = {
  component: (props: TProps) => ReactNode
  transformer: (backendData: TBackendData) => TProps | null
  // in the future we will also have position and section attributes
}

type PluginConfigs = {
  [PluginName.Feedbacks]: PluginConfig<
    ItemPageSellerFeedbacksDto,
    ItemPageSellerFeedbacksPluginModel
  >
  [PluginName.Attributes]: PluginConfig<ItemPageAttributesDto, ItemPageAttributesBlockPluginModel>
  [PluginName.Summary]: PluginConfig<ItemPageSummaryDto, ItemPageSummaryPluginModel>
  [PluginName.Description]: PluginConfig<ItemPageDescriptionDto, ItemPageDescriptionPluginModel>
}

const withCatch = <TData, TProps>(
  data: TData,
  transformPluginData: (data: TData) => TProps,
  pluginName: PluginName,
): TProps | null => {
  try {
    return transformPluginData(data)
  } catch (error) {
    logItemPageError(error, 'be_plugin_transformation_failed', `plugin: ${pluginName}`)

    return null
  }
}

const pluginConfigs: PluginConfigs = {
  [PluginName.Feedbacks]: {
    component: ItemPageSellerReviewsPlugin,
    transformer: data =>
      withCatch(data, getItemPageSellerFeedbacksPluginModel, PluginName.Feedbacks),
  },
  [PluginName.Attributes]: {
    component: ItemPageAttributesBlockPlugin,
    transformer: data =>
      withCatch(data, getItemPageAttributesBlockPluginModel, PluginName.Attributes),
  },
  [PluginName.Summary]: {
    component: ItemPageSummaryPlugin,
    transformer: data => withCatch(data, getItemPageSummaryPluginModel, PluginName.Summary),
  },
  [PluginName.Description]: {
    component: ItemPageDescriptionPlugin,
    transformer: data => withCatch(data, getItemPageDescriptionPluginModel, PluginName.Description),
  },
}

type Props = {
  data: ItemPagePluginEntityDto | undefined
}

// /**
//  * @example
//  * const ExampleSidebarComponent = (props) => {
//  *    const relevantPlugins = plugins.filter(p => p.section == 'sidebar')
//  *    return <>
//  *      {relevantPlugins.map(p => <Plugin {...p} />)}
//  *    </>
//  * }
//  */
const Plugin = ({ data }: Props) => {
  const buyerHoldoutAbTestEnabled =
    useAbTest({ abTestName: 'buyer_domain_holdout_2024q3' })?.variant === AbTestVariant.On

  if (!data) return undefined

  const pluginConfig: any = pluginConfigs[data.name]
  if (!pluginConfig) return undefined

  const componentProps = pluginConfig.transformer(data.data)
  if (!componentProps) {
    clientSideMetrics
      .counter(ITEM_PAGE_PLUGIN_ERROR_COUNT_METRIC_NAME, { name: data.name })
      .increment()
  }

  const Component = pluginConfig.component

  return (
    <>
      {data.exposure && buyerHoldoutAbTestEnabled && <ExposureBlock {...data.exposure} />}
      {componentProps && <Component {...componentProps} />}
    </>
  )
}

export default Plugin
