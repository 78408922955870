'use client'

import { useEffect } from 'react'
import { Spacer } from '@vinted/web-ui'

import { ItemDto, ItemPagePluginEntityDto } from 'types/dtos'
import Advertisement from 'components/Advertisement'

import InfoBanner from 'components/InfoBanner'
import { AdShape } from 'constants/ads'
import { GoogleTagManagerEvent } from 'constants/google'
import useGoogleTagManagerTrack from 'hooks/useGoogleTagManagerTrack'
import useSession from 'hooks/useSession'

import DetailsSection from './DetailsSection'
import { useItemPlugins } from '../hooks'
import ItemPageUserInfoPlugin from '../plugins/UserInfo'
import ItemPagePerformanceBlockPlugin from '../plugins/PerformanceBlock'
import ItemPageOfflineVerificationStatusPlugin from '../plugins/OfflineVerificationStatus'
import ItemPageElectronicsVerificationStatusPlugin from '../plugins/ElectronicsVerificationStatus'
import ItemPageItemStatusPlugin from '../plugins/ItemStatus'
import ItemPageLegalDpoFootnotePlugin from '../plugins/LegalDpoFootnote'
import ItemPageTransparencyLawPlugin from '../plugins/TransparencyLaw'

type Props = {
  item: ItemDto
  serverSidePlugins?: Array<ItemPagePluginEntityDto>
}

const Sidebar = ({ item, serverSidePlugins }: Props) => {
  const { screen: screenName } = useSession()
  const { googleTagManagerTrack } = useGoogleTagManagerTrack()
  const plugins = useItemPlugins(item)

  useEffect(() => {
    const value = parseFloat(item.price.amount)
    const price = parseFloat(item.discount_price?.amount || item.price.amount)
    const discount = Math.round((value - price) * 100) / 100
    googleTagManagerTrack(GoogleTagManagerEvent.ItemView, {
      item_name: item.title,
      item_id: item.id,
      value,
      price,
      discount,
      currency: item.currency,
      user_email: item.user.email,
      item_category_id: item.catalog_id,
      item_brand: item.brand_dto?.title,
      item_brand_id: item.brand_dto?.id,
      item_condition: item.disposal_conditions,
    })
  }, [googleTagManagerTrack, item])

  return (
    <>
      <section>
        <InfoBanner screen={screenName} />
      </section>
      <ItemPagePerformanceBlockPlugin
        performanceBlockPluginModel={plugins?.performanceBlock ?? undefined}
      />
      <ItemPageItemStatusPlugin data={plugins?.itemStatus ?? undefined} />
      {plugins?.offlineVerificationStatus && (
        <ItemPageOfflineVerificationStatusPlugin data={plugins.offlineVerificationStatus} />
      )}
      <ItemPageElectronicsVerificationStatusPlugin />
      <div
        className="box box--item-details u-border-radius-inherit"
        data-testid="item-sidebar-details-section-container"
      >
        <DetailsSection item={item} data={plugins} serverSidePlugins={serverSidePlugins} />
      </div>
      <Spacer size={Spacer.Size.Large} />
      {plugins?.userInfo && <ItemPageUserInfoPlugin data={plugins.userInfo} />}
      <ItemPageLegalDpoFootnotePlugin data={plugins?.legalDpoFootnote ?? undefined} />
      <ItemPageTransparencyLawPlugin data={plugins?.transparencyLaw ?? undefined} />
      <Advertisement shape={AdShape.Rectangle} id="ad-rectangle" isSidebarAd />
      <Advertisement shape={AdShape.Skyscraper} id="ad-skyscraper" isSidebarAd />
    </>
  )
}

export default Sidebar
