'use client'

import { ReactNode, useRef } from 'react'
import { Divider, Text } from '@vinted/web-ui'
import classNames from 'classnames'

import { InView } from 'react-intersection-observer'

import useBreakpoint from 'hooks/useBreakpoint'
import {
  AttributeFaqDto,
  AttributeFavouritableDto,
  AttributeModalDto,
  AttributeNavigationalDto,
  AttributeTextDto,
  AttributeType,
  ItemPageAttributeDto,
} from 'types/dtos'
import { itemAttributesClickEvent, itemAttributesViewEvent } from 'libs/common/event-tracker/events'
import useTracking from 'hooks/useTracking'

import { ItemPageAttributesBlockPluginModel } from './types'
import MoreInfoModal from './MoreInfoModal'
import Brand from './Brand'
import ItemInfoLink from './ItemInfoLink'

type AttributeWrapperProps = {
  code: string
  title: string
  children: ReactNode
  relativePosition?: boolean
}

const AttributeWrapper = ({
  code,
  title,
  children,
  relativePosition = false,
}: AttributeWrapperProps) => {
  return (
    <div
      className={classNames('details-list__item', {
        'u-position-relative': relativePosition,
      })}
      key={code}
      data-testid={`item-attributes-${code}`}
    >
      <div className="details-list__item-title">{title}</div>
      <div className="details-list__item-value" itemProp={code}>
        {children}
      </div>
    </div>
  )
}

const ItemPageAttributesBlockPlugin = ({
  attributes,
  itemId,
}: ItemPageAttributesBlockPluginModel) => {
  const breakpoints = useBreakpoint()
  const seenAttributeCodes = useRef<Array<string>>([])
  const { track } = useTracking()

  const renderFaqAttribute = ({ code, data }: AttributeFaqDto) => {
    return (
      <AttributeWrapper code={code} title={data.title} relativePosition>
        {data.value}
        <ItemInfoLink
          code={code}
          itemId={itemId}
          faqEntryId={Number(data.id)}
          accessChannel="product_link"
        />
      </AttributeWrapper>
    )
  }

  const renderModalAttribute = ({ code, data }: AttributeModalDto) => {
    return (
      <AttributeWrapper code={code} title={data.title} relativePosition>
        <span className="details-list__item-value--expandable">{data.value}</span>
        <MoreInfoModal
          code={code}
          title={data.title}
          body={<Text text={data.value} />}
          itemId={itemId}
        />
      </AttributeWrapper>
    )
  }

  const renderFavourtableAttribute = ({ data }: AttributeFavouritableDto) => {
    const { id, url, value, title, is_favourite } = data

    return (
      <Brand
        brandDto={{
          id: Number(id),
          path: url,
          title: value,
          is_favourite,
        }}
        itemId={itemId}
        title={title}
      />
    )
  }

  const renderTextAttribute = ({ code, data }: AttributeTextDto) => {
    return (
      <AttributeWrapper code={code} title={data.title}>
        {data.value}
      </AttributeWrapper>
    )
  }

  const renderNavigationalAttribute = ({ code, data }: AttributeNavigationalDto) => {
    const handleNavigationalAttributeClick = () => {
      track(
        itemAttributesClickEvent({
          itemId: itemId.toString(),
          attributeCode: code,
          attributeValueId: data.id,
        }),
      )
    }

    return (
      <AttributeWrapper code={code} title={data.title}>
        <a
          href={data.url}
          className="inverse u-disable-underline-without-hover"
          itemProp="url"
          data-testid={`item-attributes-${code}-link`}
          onClick={handleNavigationalAttributeClick}
        >
          {data.value}
        </a>
      </AttributeWrapper>
    )
  }

  const renderAttribute = (attribute: ItemPageAttributeDto) => {
    switch (attribute.type) {
      case AttributeType.Faq:
        return renderFaqAttribute(attribute)
      case AttributeType.Modal:
        return renderModalAttribute(attribute)
      case AttributeType.Text:
        return renderTextAttribute(attribute)
      case AttributeType.Favouritable:
        return renderFavourtableAttribute(attribute)
      case AttributeType.Navigational:
        return renderNavigationalAttribute(attribute)
      default:
        return null
    }
  }

  const handleAttributeView = (attributeCode: string) => (inView: boolean) => {
    if (!inView) return
    if (seenAttributeCodes.current.includes(attributeCode)) return

    track(
      itemAttributesViewEvent({
        itemId: itemId.toString(),
        attributeCode,
      }),
    )
    seenAttributeCodes.current.push(attributeCode)
  }

  return (
    <div className="details-list details-list--details">
      {!breakpoints.tablets && (
        <Divider testId="item-details-divider" className="u-margin-bottom-small" />
      )}
      {attributes.map(attribute => (
        <InView key={attribute.code} onChange={handleAttributeView(attribute.code)}>
          {renderAttribute(attribute)}
        </InView>
      ))}
    </div>
  )
}

export default ItemPageAttributesBlockPlugin
